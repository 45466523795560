export type AuthUserContactType = 'email'
export type AuthUserContactUsage = 'registration'

export interface ContactType {
  contactAddress: string
  contactType: AuthUserContactType
  contactUsage: AuthUserContactUsage
}

/*
 * When adding values here, please update i18n namespace "@cibo/profile/Permissions"
 */
export enum AuthUserPermission {
  // BUG REPORTING
  CREATE_BUG_REPORT = 'bug:create', // unimplemented

  // Dev
  DEBUG_USER = 'debug_user',
  TRANSLATIONS_VIEW = 'translations_view',

  // Features
  CAN_EMULATE = 'can_emulate',
  CARBON_LAB = 'carbon_lab',
  INSIGHTS = 'insights',
  LEARN_VIEW = 'learn_view',
  /** @deprecated */
  GROWER_CONTACT_READ = 'grower_contact_read',
  /** @deprecated */
  GROWER_CONTACT_UPDATE = 'grower_contact_update',
  /** @deprecated */
  PROGRAMS_EDIT = 'programs_edit',
  /** @deprecated */
  SUPPLY_SHED = 'supply_shed',
  /** @deprecated */
  INSIGHTS_LAB = 'insights_lab',

  // Programs
  COMMIT_NON_CUSTODIAL = 'commit_non_custodial',
  COMMIT_NON_CUSTODIAL_MESSAGE = 'commit_non_custodial_message',
  COMMIT_NON_CUSTODIAL_MESSAGE_CUSTOM = 'commit_non_custodial_message_custom',
  MANAGE_ENROLLMENTS = 'manage_enrollments',
  MANAGE_PROGRAMS = 'manage_programs',
  FORCE_UNCOMMIT = 'field_force_uncommit',
  FORCE_ENROLL = 'fields:force:enroll',
  FORCE_CHANGE_YEAR = 'fields:force:change_year',

  // Fields
  UPLOAD_SHAPEFILES = 'upload_shapefiles',
  FIELD_CREATE = 'field_create',
  FIELDALYTICS_IMPORT_REQUEST = 'fieldalytics:import:request',
  FIELDS_OWNER_ASSIGN = 'fields:owner:assign',
  FIELDS_OWNER_MODIFY = 'fields:owner:modify',
  FIELD_VIEW_DETAIL_REPORT = 'field_view_detail_report',
  FIELD_ASSIGN_OWNER_MESSAGE = 'field_assign_owner_message',
  FIELD_ASSIGN_OWNER_MESSAGE_CUSTOM = 'field_assign_owner_message_custom',

  //Growers Module
  GROWERS_MODULE = 'growers_module',

  // Users
  ALLOW_REGISTRATION = 'allow_reg',
  EDIT_USER = 'user_edit',
  EDIT_USER_STATUS = 'user_edit_status',
  EDIT_USER_PASSWORD = 'user_edit_password_reset',
  EDIT_USER_ORGANIZATION = 'user_edit_organization',
  USER_ARCHIVE = 'user_archive',
  USER_ROSTERED = 'user_rostered',
  USER_DASHBOARD = 'user_dashboard',
  ORG_DASHBOARD = 'org_dashboard',
  VIEW_USER_PERMISSION = 'permission_user_view',
  EDIT_USER_PERMISSION = 'permission_user_edit',
  USER_RESEND_INVITE = 'user_resend_invite',
  USER_CREATE = 'user_create',
  USER_EXTERNAL_ID_EDIT = 'user:externalId:update',
  USER_PARTNER_ID_EDIT = 'user:partnerId:update',
  USER_EDIT_ADVISOR = 'user:advisorId:edit',

  // Farms
  FARMS_MANAGE = 'farms_manage',
  FARMS_VIEW = 'farms_view',
  FARMS_ASSIGN_OWNER = 'farm_assign_owner',

  // Agreements
  MANAGE_AGREEMENTS = 'manage_agreements',
  AGREEMENTS_ADMIN_REVIEW_READ = 'agreements:adminReview:read',
  AGREEMENTS_ADMIN_REVIEW_CREATE = 'agreements:adminReview:create',
  AGREEMENTS_ATTACHMENTS_READ = 'agreements:attachments:read',
  AGREEMENTS_ATTACHMENTS_WRITE = 'agreements:attachments:write',
  AGREEMENTS_ATTACHMENTS_CREATE = 'agreements:attachments:create',
  AGREEMENTS_OFFER_READ = 'agreements:offer:read',
  AGREEMENTS_OFFER_WRITE = 'agreements:offer:write',
  AGREEMENTS_OFFER_CREATE = 'agreements:offer:create',
  AGREEMENTS_SOIL_READ = 'agreements:soil:read',
  AGREEMENTS_SOIL_WRITE = 'agreements:soil:write',
  AGREEMENTS_DATA_VALIDATION_READ = 'agreements:dataValidation:read',
  AGREEMENTS_DATA_VALIDATION_WRITE = 'agreements:dataValidation:write',
  AGREEMENTS_INSIGHTS_READ = 'agreements:insights:read',

  /** @deprecated */
  AGREEMENTS = 'agreements',

  /** Unimplemented permissions */
  SCOPE3_INVENTORY_CREATE = 'scope3:inventory:create',
  SCOPE3_INVENTORY_WRITE = 'scope3:inventory:write',
  SCOPE3_INVENTORY_READ = 'scope3:inventory:read',
  CANARY_UI = 'canaryUi',

  /** Unimplemented permission for testing */
  TEST_UNIMPLEMENTED_PERMISSION = 'test_unimplemented_permission',
}

/**
 * The inclusion of permissions in this block causes the permissions to be treated as 'debug gates'
 * to use, add the permission here and in `AuthUserPermission`, and any user with a debug permission
 * will be able to see it by virtue of logic in `profile/hooks/usePermissions`.
 */
export const UnimplementedPermissions = [
  AuthUserPermission.CREATE_BUG_REPORT,
  AuthUserPermission.AGREEMENTS_INSIGHTS_READ,
  AuthUserPermission.USER_CREATE,
  AuthUserPermission.USER_EXTERNAL_ID_EDIT,
  AuthUserPermission.USER_EDIT_ADVISOR,

  /** For testing do not remove */
  AuthUserPermission.TEST_UNIMPLEMENTED_PERMISSION,
]

export enum AuthUserLevel {
  REGISTERED = 'registered',
  SUBSCRIBED = 'subscribed',
  UNLIMITED = 'unlimited',
}

export interface CapturedIP {
  captureDate: string
  ipAddress: string
  geoLocation: string[]
  userAgents?: string[]
}

export interface DeviceInfo {
  deviceKey: string
  deviceInfo: any //IUAParser.IResult
  captureDate: string
  ipAddress: string
  geoLocation: string[]
}

interface LoginSources {
  capturedIps: CapturedIP[]
}

export type UserRole = 'user' | 'admin' | 'accountMember' | 'accountContributor' | 'accountAdmin'
export type Persona = 'grower' | 'advisor' | 'admin'

export interface UserOrganization {
  id: string
  accountId?: string
  name: string
  color?: string
  logo?: string
  contentfulOrgId?: string
}

export type UserId = number

export interface MpxUser {
  hasPassword?: boolean
  shard: string
  userPrimaryId: string
  userId: UserId
  username: string
  userPermissions?: AuthUserPermission[]
  authUserLevel?: AuthUserLevel
  publicName?: string
  userJwt?: string
  owningOrgId?: string
  contactData?: {
    businessName: string
    mobileNumber: string
    legalAddress: {
      street1: string
      street2: string
      city: string
      county: string
      stateCode: string
      postalCode: string
      countryCode: string
    }
  }
  isEmulated?: boolean

  persona?: Persona
  picture?: string
  userProfileData?: {
    contacts?: ContactType[]
    loginSources?: LoginSources
  }

  authId?: string
  roles?: Array<UserRole>
  userGroup?: string
  userAccount?: string
  userInfo?: {
    givenName: string
    familyName: string
  }
  userTimezone?: string
  userLocale?: string
  userOrganization?: UserOrganization
}
